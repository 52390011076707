import { CONSTANTS } from '$lib/defaults/constants.all';
import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry } from '@sentry/sveltekit';

const sampleRate = CONSTANTS.IS_DEV ? 0 : 1.0;

if (!CONSTANTS.IS_DEV) {
	Sentry.init({
		dsn: CONSTANTS.SENTRY_DSN,
		tracesSampleRate: sampleRate,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: sampleRate,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: sampleRate,
		enabled: !CONSTANTS.IS_DEV,
		environment: CONSTANTS.ENV,
		// If you don't want to use Session Replay, just remove the line below:
		integrations: []
	});
}
// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
